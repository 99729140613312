@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

.contact-page {
  background-color: #000;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
}

.contact-page .contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.contact-page h1 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: 600;
}

.contact-page .content-wrapper {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
}

.contact-page .form-container,
.contact-page .map-container {
  flex: 1;
}

.contact-page .form-group {
  margin-bottom: 20px;
}

.contact-page input[type="text"],
.contact-page input[type="email"],
.contact-page textarea {
  width: 100%;
  padding: 15px;
  background-color: #333;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  transition: background-color 0.3s;
}

.contact-page input[type="text"]:focus,
.contact-page input[type="email"]:focus,
.contact-page textarea:focus {
  background-color: #444;
  outline: none;
}

.contact-page textarea {
  height: 150px;
  resize: vertical;
}

.contact-page button {
  width: 100%;
  padding: 15px;
  background-color: #5ac8fa;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-page button:hover {
  background-color: #47a9d8;
}

.contact-page .error {
  color: #ff6b6b;
  font-size: 14px;
  margin-top: 5px;
}

.contact-page .map-container {
  height: 400px;
  border-radius: 5px;
  overflow: hidden;
}

.contact-page .contact-info {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.contact-page .info-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .contact-page .contact-info {
    flex-direction: column;
    align-items: flex-start;
  }
}

.contact-page .contact-info {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.contact-page .info-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-page .info-item svg {
  font-size: 24px;
  color: #5ac8fa;
}

.contact-page .info-item p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.contact-page .message-container {
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.contact-page .message-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.contact-page .success-message {
  background-color: rgba(76, 175, 80, 0.1);
  border: 1px solid #4caf50;
  color: #4caf50;
}

.contact-page .error-message {
  background-color: rgba(244, 67, 54, 0.1);
  border: 1px solid #f44336;
  color: #f44336;
}

.contact-page .message-icon {
  font-size: 24px;
  margin-right: 10px;
  vertical-align: middle;
}

.contact-page .message-text {
  vertical-align: middle;
}

@media (max-width: 768px) {
  .contact-page .content-wrapper {
    flex-direction: column;
  }

  .contact-page .map-container {
    height: 300px;
  }

  .contact-page .contact-info {
    flex-direction: column;
    align-items: center;
  }
}