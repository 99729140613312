.register-freelancer {
  max-width: 480px;
  width: 100%;
  margin: 3rem auto;
  padding: 2.5rem;
  background-color: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.register-freelancer h2 {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
}

.register-freelancer .social-sign-in {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.register-freelancer .divider {
  text-align: center;
  margin: 2rem 0;
  color: #7f8c8d;
  position: relative;
}

.register-freelancer .divider::before,
.register-freelancer .divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #ecf0f1;
}

.register-freelancer .divider::before {
  left: 0;
}

.register-freelancer .divider::after {
  right: 0;
}

.register-freelancer form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.register-freelancer input {
  width: 100%;
  padding: 0.875rem;
  font-size: 1rem;
  background-color: #f7f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

.register-freelancer input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.register-freelancer .password-input {
  position: relative;
}

.register-freelancer .password-input input {
  width: 100%;
  padding-right: 2.5rem;
}

.register-freelancer .password-toggle {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #7f8c8d;
  font-size: 1.1rem;
}

.register-freelancer .btn {
  width: 100%;
  padding: 0.875rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-freelancer .btn-primary {
  background-color: #3498db;
  color: white;
}

.register-freelancer .btn-primary:hover {
  background-color: #2980b9;
}

.register-freelancer .btn-secondary {
  background-color: transparent;
  color: #3498db;
  border: 1px solid #3498db;
  margin-top: 1rem;
}

.register-freelancer .btn-secondary:hover {
  background-color: #f0f8ff;
}

.error-message {
  color: #e74c3c;
  background-color: #fdf2f2;
  border: 1px solid #e74c3c;
  border-radius: 4px;
  padding: 0.75rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

/* Google Sign-In Button Styles */
.register-freelancer .social-sign-in button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.875rem;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #ffffff;
  color: #757575;
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.register-freelancer .social-sign-in button:hover {
  background-color: #f5f5f5;
}

.register-freelancer .social-sign-in button svg {
  margin-right: 0.5rem;
}