@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

/* Reset and base styles */
*, *::before, *::after {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
}


.app-body {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
}

.app-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  flex: 1;
}

.app-header {
  background-color: #333;
  color: #fff;
  padding: 1rem 0;
}

.app-header nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.app-header nav ul li a {
  color: #fff;
  text-decoration: none;
}

.app-main {
  padding: 2rem 0;
}

.app-footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  width: 100%;
}

/* Global button styles */
.app-btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.app-btn-primary {
  background-color: #007bff;
  color: #fff;
}

.app-btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

/* Utility classes */
.text-center {
  text-align: center;
}

.mt-2 {
  margin-top: 2rem;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .app-container {
    padding: 0 10px;
  }
}