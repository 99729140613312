.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.popup-overlay.closing {
  opacity: 0;
}

.popup-content {
  position: relative;
  background-color: #ffffff;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
  transform: scale(1);
  transition: transform 0.5s ease-out;
}

.popup-overlay.closing .popup-content {
  transform: scale(0.9);
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #777;
  transition: color 0.3s ease;
}

.popup-close:hover {
  color: #333;
}

.popup-content h2 {
  color: #2980b9;
  margin-bottom: 1.2rem;
  font-size: 1.8rem;
}

.popup-content p {
  color: #34495e;
  margin-bottom: 1rem;
  line-height: 1.6;
  font-size: 1.1rem;
}