@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

.pdn-container {
  font-family: 'Open Sans', sans-serif;
  background-color: #ffffff;
  color: #333333;
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.pdn-heading {
  color: #3498db;
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 2.5rem;
  font-weight: 600;
}

.pdn-form-group {
  margin-bottom: 1rem;
}

.pdn-label {
  display: block;
  color: #333333;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.3rem;
}

.pdn-input,
.pdn-textarea,
.pdn-select {
  width: 100%;
  padding: 0.75rem;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 6px;
  color: #333333;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.3s ease;
}

.pdn-input:focus,
.pdn-textarea:focus,
.pdn-select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.pdn-textarea {
  min-height: 100px;
  resize: vertical;
}

.pdn-form-row {
  display: flex;
  gap: 1rem;
}

.pdn-form-row .pdn-form-group {
  flex: 1;
}

.pdn-radio-group {
  display: flex;
  gap: 1.5rem;
}

.pdn-radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  color: #333333;
}

.pdn-radio-input {
  margin-right: 0.5rem;
}

.pdn-file-input {
  width: 100%;
  padding: 0.75rem;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 6px;
  color: #333333;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.3s ease;
}

.pdn-file-info {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.pdn-auth-section {
  margin-top: 1rem;
}

.pdn-divider {
  text-align: center;
  margin: 1rem 0;
  color: #666;
  font-size: 1rem;
}

.pdn-btn {
  display: block;
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Open Sans', sans-serif;
}

.pdn-btn-primary1 {
  padding-bottom: 1rem;
  margin-bottom: 5rem;
  font-size: 1rem;
}

.pdn-btn-primary2 {
  background-color: #3498db;
  color: #ffffff;
  font-size: 1rem;
}

.pdn-btn-primary2:hover {
  background-color: #2980b9;
}

.pdn-btn-primary2:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.pdn-btn-secondary {
  font-size: 1rem;
  background-color: #ecf0f1;
  color: #3498db;
  border: 1px solid #3498db;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pdn-btn-secondary:hover {
  background-color: #d6eaf8;
}

.pdn-password-input {
  position: relative;
}

.pdn-password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 1rem;
}

.pdn-error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 0.75rem;
  margin-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

/* Google Sign-In Button Styles */
.pdn-container .google-login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pdn-container .google-login-button:hover {
  background-color: #357ae8;
}

.pdn-container .google-login-button img {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.pdn-hint {
  margin-bottom: 10px;
  text-align: center;
}

.pdn-hint p {
  color: #666;
  font-size: 0.9em;
  font-style: italic;
}