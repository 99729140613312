.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #000;
  color: #fff;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.logo {
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 2px;
  white-space: nowrap; /* Prevent logo text from wrapping */
}

.logo a {
  color: #3498db;
  text-decoration: none;
  margin-right: 10px; /* Reduced margin */
}

.beta-tag {
  font-size: 0.7rem; /* Reduced font size */
  background-color: #404040;
  color: #f39c12;
  padding: 2px 3px; /* Reduced padding */
  border-radius: 3px;
  margin-left: 2px; /* Reduced margin */
  font-weight: 600;
}

nav {
  display: flex;
  align-items: center;
}

nav a {
  color: #fff;
  text-decoration: none;
  margin-left: 1rem; /* Reduced margin */
  font-size: 1.1rem; /* Slightly reduced font size */
  letter-spacing: 1px;
  font-weight: 500;
  white-space: nowrap; /* Prevent nav text from wrapping */
}

nav a:hover,
nav a.active {
  color: #3498db;
}


.how-it-works {
  padding: 4rem 2rem;
  background-color: #f8f9fa;
  color: #333;
  text-align: center;
}

.how-it-works h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 0.5rem;
  letter-spacing: 4px;
}

.how-it-works .subtitle {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 3rem;
  letter-spacing: 2px;
}

.steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
}

.step {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
}

.icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #3498db;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem;
  font-size: 1.5rem;
}

.step h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

.step p {
  font-size: 0.9rem;
  color: #6c757d;
  line-height: 1.6;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .header {
    padding: 0.5rem 1rem; /* Reduced padding */
  }

  .logo {
    font-size: 1.2rem; /* Reduced font size */
  }

  .beta-tag {
    font-size: 0.6rem; /* Further reduced font size */
  }

  nav a {
    font-size: 0.8rem; /* Further reduced font size */
    margin-left: 0.5rem; /* Further reduced margin */
  }
}