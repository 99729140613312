/* Footer styling */
footer {
    width: 100%;
    padding: 2rem 1rem;
    background-color: #000; /* Match the rest of the site's background */
    color: #fff;
    text-align: center;
    position: relative;
    bottom: 0;
    left: 0;
  }
  
  footer p {
    margin: 0;
    font-size: 1rem;
    letter-spacing: 1px;
    color: #fff;
  }
  
  footer a {
    color: #69b9ff;
    text-decoration: none;
  }
  
  footer a:hover {
    color: #2980b9;
    text-decoration: underline;
  }
  
  footer .social-icons {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  footer .social-icons a {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  footer .social-icons a:hover {
    color: #69b9ff;
  }
  