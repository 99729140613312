.auth-container {
    font-family: 'Open Sans', sans-serif;
    color: #333333;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }
  
  .auth-container h3 {
    color: #333333;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .auth-form-group {
    margin-bottom: 1rem;
  }
  
  .auth-label {
    display: block;
    color: #333333;
    font-weight: 600;
    font-size: 1rem;
  }
  
  .auth-input {
    width: 100%;
    padding: 0.75rem;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 6px;
    color: #333333;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    transition: all 0.3s ease;
  }
  
  .auth-input:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
  
  .auth-password-input {
    position: relative;
  }
  
  .auth-password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .auth-btn {
    display: block;
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Open Sans', sans-serif;
  }
  
  .auth-btn-primary {
    background-color: #3498db;
    color: #ffffff;
    font-size: 1rem;
  }
  
  .auth-btn-primary:hover {
    background-color: #2980b9;
  }
  
  .auth-btn-primary:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
  
  .auth-btn-secondary {
    font-size: 1rem;
    background-color: #ecf0f1;
    color: #3498db;
    border: 1px solid #3498db;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
  
  .auth-btn-secondary:hover {
    background-color: #d6eaf8;
  }
  
  .auth-divider {
    text-align: center;
    margin: 1rem 0;
    color: #666;
    font-size: 1rem;
  }
  
  .auth-error-message {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 0.75rem;
    margin-top: 1rem;
    font-size: 0.9rem;
    text-align: center;
  }
  
  .auth-container .google-login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4285F4;
    color: white;
    border: none;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-container .google-login-button:hover {
    background-color: #357ae8;
  }
  
  .auth-container .google-login-button img {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }
  
  .auth-logged-in {
    text-align: center;
    padding: 1rem;
    background-color: #e8f7f2;
    border-radius: 6px;
    margin-bottom: 1rem;
  }
  
  .auth-logout-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  }
  
  .auth-logout-btn:hover {
    background-color: #c0392b;
  }